export const colors = [
    '#662C91',
    '#17A398',
    '#EE4266',
    '#DAEDBD',
    '#0F4C5C',
    '#E5B181',
    '#F4B9B2',
    '#7DBBC3',
    '#33312E',
    '#ED652E'
]
