import {EventAction} from "../actions/Event";

export interface AttendanceState {
}

export const initialEventState: AttendanceState = {
}

export function eventReducer(state: AttendanceState = initialEventState, action: EventAction) {
    return {};
}

